/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

/* Chat Screen Styles */
.chat-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f7f7f7;
}

/* Chat Header */
.chat-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  cursor: pointer;
  margin-right: 15px;
}

.chat-title {
  font-size: 20px;
  font-weight: bold;
  color: #f2f2f2;
}

/* Chat messages */
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

/* Message Styles */
.message {
  max-width: 70%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}

.sent {
  background-color: #007bff; /* Blue for user messages */
  align-self: flex-end; /* Align to the right */
  border-top-right-radius: 0; /* Remove the top right corner */
  margin-left: 27%;
}

/* Owner's message */
.received {
  background-color: #555; /* Dark gray for owner's messages */
  align-self: flex-start; /* Align to the left */
  border-top-left-radius: 0; /* Remove the top left corner */
}

/* Chat input */
.chat-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Container for the message footer */
.message-footer {
  display: flex;
  justify-content: flex-end;
  padding: 2px 8px;
  font-size: 12px;
  color: #667781; /* Light gray text color */
  user-select: none;
}

/* Inner container for time and ticks */
.message-info {
  display: flex;
  align-items: center;
  gap: 4px; /* Space between time and ticks */
}

/* Message time */
.message-time {
  font-size: 11px;
  color: white; /* Light gray text color */
}

/* Message ticks (single ✔ or double ✔✔) */
.message-ticks {
  font-size: 13px;
  font-weight: bold;
  margin-left: 10%;
}

.double-tick {
  color: greenyellow;
}

.double-ticks {
  color: white;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 768px) {
  .message-time {
    font-size: 10px;
    opacity: 0.7;
    position: absolute;
    bottom: 1px;
    right: 10px;
  }

  .message-ticks {
    font-size: 0.6rem;
    margin-left: 16%;
    bottom: 2px; /* Adjust as per the message bubble height */
    display: flex; /* Flex for alignment if needed */
    align-items: center;
  }
}
