.search-results {
  margin: 20px auto;
  width: 90%;
  max-width: 1200px;
}

.results-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.property-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.property-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.property-image-container {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
  overflow: hidden;
}

.property-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.property-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
}

.property-card-header h4 {
  font-size: 1.5rem;
  color: #7851a9; /* Using Zonefy's core color */
  margin: 0;
}

.property-type {
  font-size: 0.9rem;
  padding: 5px 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #555;
}

.property-details {
  padding: 15px;
}

.property-details p {
  font-size: 1rem;
  margin: 10px 0;
  color: #555;
}

.property-details span {
  font-weight: 600;
  color: #333;
}

.search-instruction {
  font-size: 1.2rem; /* Slightly larger text for readability */
  color: #6c757d; /* Subtle gray for instructions */
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.no-properties {
  font-size: 1.5rem; /* Larger text to emphasize the message */
  color: #dc3545; /* Red to grab attention */
  font-weight: bold; /* Make it stand out */
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .property-card {
    padding: 10px;
  }

  .property-card-header h4 {
    font-size: 1.3rem;
  }

  .results-title {
    font-size: 1.5rem;
  }
}
