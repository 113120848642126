/* General body settings */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  width: 100%;
}

.fixed-header {
  position: sticky;
  top: 0;
  background-color: #7851a9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

.left-section {
  display: flex;
  align-items: center;
  margin-left: 1%;
}

.left-section h2 {
  margin-left: 10px;
}

.right-section {
  display: flex;
  gap: 15px;
  margin-right: 1%;
  cursor: pointer;
}

.text1 a {
  text-decoration: none;
  color: inherit;
}

.text1:hover {
  transform: scale(1.02);
  font-size: large;
}

.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.logo-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  display: block;
  opacity: 0.8;
}

.image-text {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
}

/* Search container settings */
.search-container {
  position: relative;
  max-width: 90%;
  margin: 20px auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  flex-direction: row; /* Set rows for larger screens */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.form-group {
  flex: 1 1 auto; /* Adjust width to make it flexible in the row */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group.check-in,
.form-group.check-out {
  flex-basis: 12%; /* Make the check-in and check-out fields shorter */
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="date"] {
  width: 100%; /* Ensure the date field fills its container */
  font-family: Arial, sans-serif;
}

.search-button {
  padding: 12px 20px;
  background-color: #7851a9;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  text-transform: uppercase;
  width: auto; /* Auto-width to prevent it from stretching too much */
  flex-shrink: 0;
  margin-top: 10px;
}

.search-button:hover {
  background-color: #5a3c7d;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.confirm-button {
  background-color: #d9534f;
  color: white;
}

.cancel-button {
  background-color: #5bc0de;
  color: white;
}

.close-icon {
  align-self: flex-end;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar {
  width: 250px;
  background-color: #7851a9;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 60px;
  transition: transform 0.3s ease;
  z-index: 10;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar nav ul li {
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Center them vertically */
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 10px;
}

.sidebar-open .main-content {
  margin-left: 250px;
}

.sidebar nav ul li svg {
  margin-right: 15px; /* Space between icon and text */
  color: #ffc107;
  transition: transform 0.3s ease, color 0.3s ease;
}

.sidebar nav ul li:hover svg {
  color: #ffd740;
  transform: scale(1.1);
}

/* Link styling */
.sidebar nav ul li a,
.sidebar nav ul li span {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 500;
  transition: color 0.3s ease;
  display: inline-block;
}

.sidebar nav ul li a:hover,
.sidebar nav ul li span:hover {
  color: #ffc107;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.body.sidebar-open .main-content {
  margin-left: 250px;
}

/* Mobile adjustments */
@media screen and (max-width: 900px) {
  .logo-image {
    width: 100%;
    max-height: 450px;
    opacity: 0.9;
  }

  .search-container {
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Adjust the container to take full width on mobile */
    padding: 10px; /* Ensure padding doesn't overflow */
  }

  .form-group {
    flex: 1 1 100%; /* Full width for mobile */
    width: 100%; /* Make the form inputs/selects span the full width */
  }

  .form-group input,
  .form-group select {
    width: 100%; /* Full width for inputs and selects */
  }

  .search-button {
    width: 100%; /* Ensure the button is full width on mobile */
  }

  .image-text {
    font-size: 1rem; /* Smaller font size for mobile */
    top: 13%; /* Adjust the positioning */
    left: 50%;
    transform: translateX(-50%); /* Center it horizontally */
    width: 90%; /* Ensure it doesn't overflow horizontally */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Keep the shadow, but soften it slightly */
    padding: 10px;
    line-height: 1.4; /* Improve line spacing for readability */
    font-weight: bold;
  }

  /* Adjust the container and image for smaller screens */
  .image-container {
    height: auto; /* Allow the container to resize properly */
  }

  .logo-image {
    max-height: 350px; /* Reduce the image height for mobile */
    opacity: 1.7;
  }
}
