/* .body {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 2rem;
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .fixed-header {
  width: 100%;
  background-color: #7851a9;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  color: white;
} */

.left-section h2 {
  margin: 0;
  font-size: 1.5rem;
}

.email-verified-container {
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
}

.email-verified-container h1 {
  color: #7851a9;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.email-verified-container p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.signin-link {
  text-decoration: none;
}

.signin-btn {
  background-color: #7851a9;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.signin-btn:hover {
  background-color: #45a049;
}
