/* General styles */
.listedProperties {
  padding: 20px;
  font-family: "Roboto", sans-serif;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 700;
  text-align: center;
}

/* Rectangle container */
.rectangle-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap; /* Wraps to new lines for smaller screens */
}

/* Rectangle card styles */
.rectangle {
  width: calc(33.33% - 20px); /* Three cards per row with spacing */
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 15px;
  transition: transform 0.2s ease-in-out;
  border: 1px solid #e0e0e0;
}

.rectangle:hover {
  transform: scale(1.02);
}

/* Image styles */
.logo-image1 {
  width: 100%; /* Ensures images are responsive */
  height: 250px; /* Fixed height for consistency */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Header text */
.header-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

/* Property details */
.details-container {
  text-align: left;
}

.description {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
  line-height: 1.5;
  overflow-y: auto;
  max-height: 100px;
}

.property-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.property-info span {
  font-size: 0.9rem;
  color: #666;
}

.property-features {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Wrap features on smaller screens */
  font-size: 0.85rem;
  margin-bottom: 10px;
}

.rent-button {
  padding: 8px 12px;
  background-color: #7851a9;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: auto;
  transition: all 0.3s ease;
}

.rent-button:hover {
  background-color: #5e3a8d;
  transform: scale(1.05);
}

/* Pagination controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination-controls button {
  padding: 6px 12px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
}

.pagination-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 0.9rem;
  color: #555;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .rectangle {
    width: calc(50% - 20px); /* Two cards per row */
  }

  .logo-image1 {
    height: 200px; /* Adjust image height */
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .rectangle {
    width: calc(100% - 3px); /* Single card per row */
    padding: 10px;
  }

  .header-text {
    font-size: 1.1rem;
  }

  .description {
    font-size: 0.85rem;
    max-height: 80px; /* Shorter description container */
  }

  .logo-image1 {
    height: 180px; /* Adjust image height */
  }

  .property-info span,
  .property-features span {
    font-size: 0.8rem;
  }

  .rent-button {
    padding: 6px 10px;
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .rectangle {
    padding: 8px;
    border-radius: 8px;
  }

  .logo-image1 {
    height: 150px;
  }

  .header-text {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .description {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .rent-button {
    padding: 5px 8px;
    font-size: 0.8rem;
  }

  .pagination-controls button {
    padding: 5px 8px;
    font-size: 0.8rem;
  }
}
