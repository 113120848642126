.backs-button {
  background-color: #f0f0f0;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
}

.backs-button:hover {
  background-color: #ddd;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

.delete-button {
  margin-left: 10px;
}

.no-properties-message {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 8px 12px;
  margin: 0 5px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination-controls span {
  font-size: 16px;
  margin: 0 10px;
}

.message-button {
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 1%;
}

.message-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.message-button svg {
  font-size: 16px; /* Adjust icon size */
}
