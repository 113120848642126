.place-property-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  margin-left: 10px;
  font-size: 24px;
  color: #333;
  margin-top: 20px;
}

.property-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

textarea {
  resize: vertical;
}

.form-group label {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
}

.form-group textarea {
  resize: none;
  height: 80px;
}

.image-upload-section input {
  border: none;
}

.image-preview {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.submit-button {
  padding: 12px;
  font-size: 16px;
  background-color: #7851a9;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #7851a9;
}
