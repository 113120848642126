.form-group1 {
  margin-bottom: 1rem;
  margin-left: 5%;
}

.form-group1 label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.form-group1 input {
  width: 90%;
  padding: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  background-color: #f7f7f7;
  transition: all 0.3s ease;
}

.form-group1 input:focus {
  border-color: #66a6ff;
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 8px rgba(102, 166, 255, 0.3);
}

.input-container {
  position: relative;
  width: 90%; /* Matches the input width */
}

.input-container input {
  width: 100%;
  padding: 0.8rem;
  padding-right: 2.5rem; /* Add padding to the right for the icon */
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1rem;
  background-color: #f7f7f7;
  transition: all 0.3s ease;
}

.input-container input:focus {
  border-color: #66a6ff;
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 8px rgba(102, 166, 255, 0.3);
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  cursor: pointer;
}
