.admin-dashboard {
  font-family: "Roboto", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f7fa;
}

h1 {
  text-align: center;
  color: #333;
  font-weight: 700;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
}

.section {
  margin-bottom: 40px;
}

.property-card,
.user-card,
.request-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.property-card:hover,
.user-card:hover,
.request-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.property-image {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.property-info {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align content to the left */
}

.property-info:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.property-info h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007bff;
}

.property-info p {
  margin: 5px 0;
  font-size: 1.1em;
  color: #666;
}

.property-info p:first-of-type {
  margin-top: 0;
}

.property-info p:last-of-type {
  margin-bottom: 0;
}

.user-card h3,
.request-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007bff;
}

.user-card p,
.request-card p {
  margin: 5px 0;
  font-size: 1.1em;
  color: #666;
}

.property-actions button,
.delete-button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.property-actions button.reject-button {
  background-color: #dc3545;
}

.property-actions button:hover,
.delete-button:hover {
  background-color: #218838;
}

.approve-button,
.reject-button {
  margin-top: 10px;
  width: auto;
  text-align: center;
}

.admin-statistics {
  margin-bottom: 30px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

canvas {
  width: 100% !important;
  max-width: 100% !important;
}

.properties-list,
.users-list,
.requests-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

button.delete-button {
  background-color: #dc3545;
}

.search-bar {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.toggle-section {
  text-align: center;
  margin-bottom: 20px;
}

.toggle-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-section button:hover {
  background-color: #0056b3;
}

.search-containers {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.search-bar {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  flex: 1;
  outline: none;
  transition: border-color 0.3s;
}

.search-bar:focus {
  border-color: #007bff;
}

.search-buttons {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-bottom: 1.6%;
}

.search-buttons:hover {
  background-color: #0056b3;
}

.search-buttons:active {
  transform: scale(0.95);
}

.user-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-card h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.user-card p {
  margin: 5px 0;
  color: #666;
  font-size: 14px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

button {
  padding: 5px 10px;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  .search-bar {
    max-width: 100%;
  }

  .property-card,
  .user-card {
    max-width: 100%;
  }

  .admin-statistics canvas {
    max-width: 100%;
  }
}
