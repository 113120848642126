/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
}

/* Property Screen Container */
.property-screen {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
}

.property-title {
  font-size: 24px;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
}

.property-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.back-button {
  margin-right: 15px;
  cursor: pointer;
  color: #333;
}

.property-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Property Image Section */
/* Property Image Section */
.property-image-container {
  flex: 2;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%; /* Ensure container takes full width */
  height: 100%; /* Ensure container takes full height */
}

.property-pic {
  width: 545px; /* Fixed width */
  height: 410px;
  object-fit: cover;
  border-radius: 10px;
}

.image-carousel {
  position: relative;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.nav-button:first-of-type {
  left: 10px;
}

.nav-button:last-of-type {
  right: 10px;
}
/* Property Info Container */
.property-info-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  margin-left: 20px;
}

/* Owned By Section */
.owned-by {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.owned-by h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
}

.owned-by p {
  font-size: 14px;
  color: #555;
}

/* Contact Section */
.contact-section {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.contact-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #007bff;
}

.contact-section p {
  font-size: 16px;
  color: #333;
}

.chat-button {
  background-color: #7851a9;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
}

.chat-button:hover {
  background-color: #7851a9;
}

/* Property Description */
.property-description {
  display: flex;
  flex-direction: column; /* Ensure content stacks properly */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  max-height: 250px; /* Set a max height */
  overflow-y: auto; /* Allow scrolling if the content exceeds the height */
}

.property-description h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.property-description p {
  font-size: 1rem; /* Slightly adjust font size */
  color: #555;
  line-height: 1.6; /* Line height for better readability */
  text-align: left;
  word-wrap: break-word; /* Ensure long words break correctly */
  padding-right: 5px; /* Add padding to avoid content touching scrollbar */
}

.delete-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 10px; /* Increased padding for a larger button */
  font-size: 30px; /* Increased font size for the icon */
  width: 40px; /* Increased width */
  height: 40px; /* Increased height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-image-icon {
  position: absolute;
  bottom: 15px;
  left: 10%;
  color: green;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.add-image-icons {
  position: absolute;
  bottom: 15px;
  left: 10%;
  color: black;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid;
}

.edit-form {
  margin-top: 20px;
}

.edit-form label {
  display: block;
  margin-bottom: 10px;
}

.edit-form input,
.edit-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.edit-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.save-button {
  background-color: #4caf50;
  color: white;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.edit-icon {
  cursor: pointer;
}

.interested-renters {
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f9f9f9; /* Light gray background */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.renters-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333; /* Dark text color */
  text-align: center;
}

.renters-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
}

.renter-item {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #ffffff; /* White background */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.2s, box-shadow 0.2s;
}

.renter-item:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.renter-details {
  font-size: 1rem;
  color: #555; /* Muted text color */
}

.renter-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #222; /* Slightly darker for emphasis */
}

.renter-email,
.renter-phone {
  font-size: 0.9rem;
  color: #555;
}

.message-buttons {
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.message-buttons:hover {
  background-color: #45a049; /* Slightly darker green */
}

/* Responsive Design */
@media (max-width: 768px) {
  .property-row {
    flex-direction: column;
  }

  .property-info-container {
    margin-left: 0;
    margin-top: 20px;
  }

  .property-pic {
    width: 300px; /* Fixed width */
    height: 200px; /* Fixed height */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    border-radius: 8px; /* Keeps rounded corners */
    margin-top: 10px;
  }
}
