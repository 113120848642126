/* Global Container */
.messages-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f4f6f9;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Chat List View */
.chat-list-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #7851a9;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.headers h3 {
  color: white;
}

.chat-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-item {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.chat-item:hover {
  background-color: #f0f4ff;
  transform: translateY(-3px);
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-name,
.chat-email {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds '...' at the end of the truncated text */
  max-width: 100%; /* Ensures it doesn’t go beyond the container */
}

.chat-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.chat-email {
  font-size: 0.85rem;
  color: #666;
}

.chat-timestamp {
  font-size: 0.75rem;
  color: #aaa;
  margin-top: 5px;
  text-align: right;
}

/* Chat Conversation View */
.chat-conversation-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #e8f0fe;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #7851a9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.chat-header h4 {
  color: #f2f2f2;
}

.messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 70%;
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 10px;
  line-height: 1.5;
  color: white;
  word-wrap: break-word;
}

.message.sent {
  align-self: flex-end;
  background-color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message.received {
  align-self: flex-start;
  background-color: #6c757d;
}

.message-footer {
  font-size: 0.75rem;
  margin-top: 5px;
  text-align: right;
}

/* Message Input */
.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  position: sticky; /* Keeps the input visible at the bottom */
  bottom: 0; /* Sticks to the bottom */
  z-index: 10; /* Ensures it stays above other content */
}

.message-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
}

.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Back Button */
.back-button,
.back-buttons {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-top: 4px solid #c45628; /* Match your app's theme color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-item.admin {
  background-color: #f8f9fa; /* Light background for admin chats */
  border-left: 4px solid #007bff; /* Highlight with a border */
}
.admin-section h4 {
  color: #007bff; /* Admin section header color */
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-left: 2%;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .chat-header h2,
  .headers h3 {
    font-size: 1rem;
  }

  .message {
    max-width: 90%;
  }

  .messages {
    max-height: calc(100vh - 120px); /* Adjust height for small screens */
  }

  .send-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .message-input input {
    font-size: 0.9rem;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .header,
  .chat-header {
    padding: 10px;
  }

  .chat-item {
    padding: 10px;
  }

  .message-input input {
    font-size: 0.85rem;
  }

  .send-button {
    padding: 6px 10px;
    font-size: 0.85rem;
  }
}
